import React from "react"
import Layout from "../components/layout"
import Banner from "../components/section/Banner"
import Services from "../components/section/Services"
import Events from "../components/section/Events"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Banner />
    <Services />
    <Events />
  </Layout>
)

export default IndexPage
