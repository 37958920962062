import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { motion } from "framer-motion"
import { MEDIA_QUERIES, COLORS } from "../../constants"
import { Container, Heading, Text } from "../styled/Styled"

const ImgContainer = styled(motion.div)`
  max-width: 800px;
  max-height: 400px;
  border-right: 8px solid ${COLORS.BLACK};
  z-index: -2;
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    max-width: 90%;
    margin: auto;
  }
`

const Article = styled(motion.div)`
  position: absolute;
  padding: 2%;
  max-width: 600px;
  top: 3rem;
  right: 10%;
  background-color: white;
  border-bottom: 8px solid ${COLORS.BLACK};
  border-left: 8px solid ${COLORS.BLACK};
  box-shadow: 7px 10px 19px -13px rgba(0, 0, 0, 0.75);

  &:after {
    content: "";
    position: absolute;
    top: -18px;
    height: 200px;
    width: 300px;
    right: -20px;
    border: ${COLORS.BLACK} solid 8px;
    z-index: -1;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: -26px;
    height: 200px;
    width: 300px;
    left: -28px;
    border: ${COLORS.BLACK} solid 8px;
    z-index: -1;
  }

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    position: relative;
    border-bottom: none;
    max-width: 90%;
    margin: auto;
    right: 0;
    top: 0;
    margin-top: 18px;
    &:before {
      display: none;
    }
  }
`

const Banner = () => {
  const { banner } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "anke-header.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Container>
      <ImgContainer
        initial={{ opacity: 0, x: "-300px" }}
        animate={{
          opacity: 1,
          x: 0,
          transition: { duration: 2, type: "spring", stiffness: 40 },
        }}
      >
        <Img fluid={banner.childImageSharp.fluid} />
      </ImgContainer>
      <Article>
        <Heading
          initial={{ opacity: 0, x: "300px" }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { duration: 2, type: "spring", stiffness: 40 },
          }}
        >
          Anke Clinck
        </Heading>

        <Text
          initial={{ opacity: 0, x: "300px" }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { duration: 2, type: "spring", stiffness: 40 },
          }}
        >
          Anke is an all-round urban dancer, with a background in physical
          training whose always willing to learn new techniques and moves to
          improve upon past performances. A well-trained and motivated artist
          with nerves of steel and a background performing in and choreographing
          for TV-shows, music videos, battles and competitions.
        </Text>
      </Article>
    </Container>
  )
}

export default Banner
