import React from "react"
import { Container } from "../styled/Styled"
import EventList from "../event/EventList"
import { StyledTitle } from "../styled/Styled"

const Events = () => {
  return (
    <Container>
      <StyledTitle>UPCOMING EVENTS</StyledTitle>
      <EventList />
    </Container>
  )
}

export default Events
