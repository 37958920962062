import React from "react"
import { motion } from "framer-motion"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { MEDIA_QUERIES, COLORS } from "../../constants"
import {
  Container,
  Flex,
  Text,
  Subheading,
  InternalLink,
} from "../styled/Styled"

const ImgContainer = styled(motion.div)`
  max-height: 700px;
  position: relative;
  width: 40%;
  &:after {
    content: "";
    position: absolute;
    top: -18px;
    height: 400px;
    width: 300px;
    left: -20px;
    border: ${COLORS.BLACK} solid 8px;
    z-index: -1;
    box-shadow: 7px 10px 19px -13px rgba(0, 0, 0, 0.75);
  }

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 90%;
    margin: auto;
    &:after {
      display: none;
    }
  }
`

const StyledSubheading = styled(Subheading)`
  display: inline-flex;
  background-color: ${COLORS.PRIMARY};
  color: white;
  margin-right: auto;
  padding: 0.5rem;
  padding-left: 2rem;
`

const Sections = styled(motion.div)`
  max-width: 650px;
  box-shadow: 7px 10px 19px -13px rgba(0, 0, 0, 0.75);
  border-left: 16px solid ${COLORS.BLACK};
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    max-width: 100%;
    width: 100%;
    margin: auto;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 8px solid ${COLORS.BLACK};
  padding: 1rem 0;
  padding-right: 1rem;
  background-color: white;
`

const Services = () => {
  const { dance } = useStaticQuery(graphql`
    query {
      dance: file(relativePath: { eq: "anke-dance.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Container>
      <Flex>
        <ImgContainer
          initial={{ opacity: 0, y: "-300px" }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1,
              delay: 1,
              type: "spring",
              stiffness: 40,
            },
          }}
        >
          <Img fluid={dance.childImageSharp.fluid} />
        </ImgContainer>

        <Sections
          initial={{ opacity: 0, y: "300px" }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1,
              delay: 1,
              type: "spring",
              stiffness: 40,
            },
          }}
        >
          <Section>
            <StyledSubheading>Dancer</StyledSubheading>
            <Text style={{ marginLeft: "2rem" }}>
              I am an independent freelance dancer with a wide range of
              interests. I coach and choreograph several high level teams who
              also perform on TV. I am an organisor, a dance teacher (regular
              classes & workshops) and freestyle dancer. You can also book me to
              make choreography/dance for shows or specific theme/company
              parties. I work with a lot of dancers with specific skill sets,
              which makes me learn new stuff every single day, but also makes it
              perfect to book us for shows.
            </Text>
            <div style={{ display: "flex" }}>
              <InternalLink
                to="/dancer/classes"
                style={{ marginLeft: "auto", marginRight: "1rem" }}
              >
                Class Schedule
              </InternalLink>
              <InternalLink to="/dancer/choreographer">More Info</InternalLink>
            </div>
          </Section>
          <Section style={{ border: "none" }}>
            <StyledSubheading>Personal Coach</StyledSubheading>
            <Text style={{ marginLeft: "2rem" }}>
              As I am a certified Physical Coach with a Master Degree in
              Personal Training and Coaching (Gent University), I can help you
              make a training schedule to help you reach your individual
              physical goals. You can join my regular workout classes, or book
              me for individual adjusted trainings.
            </Text>
            <InternalLink to="/coach" style={{ marginLeft: "auto" }}>
              More Info
            </InternalLink>
          </Section>
        </Sections>
      </Flex>
    </Container>
  )
}

export default Services
